<template>
    <div>
        <pm-Card>
            <template v-slot:title>
                Endereço e contato
            </template>
            <template v-slot:subtitle>
                Insira os dados abaixo
            </template>
            <template v-slot:content>
                <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-1 cep_responsive">
                        <label for="cep">CEP</label>
                        <pm-InputMask  mask="99.999-999"  v-model="cepInfo"  placeholder="99.999-999" />
                    </div>
                    <div class="field col-12 md:col-3">
                        <label for="logradouro">Logradouro</label>
                        <pm-InputText id="logradouro" v-model="info.logradouro"/>
                    </div>
                    <div class="field col-12 md:col-1 num_responsive">
                        <label for="firstname">Número</label>
                        <pm-InputText id="numero" v-model="info.numero"/>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="firstname">Complemento</label>
                        <pm-InputText id="complemento" v-model="info.complemento"/>
                    </div>
                    <div class="field col-12 md:col-2" >
                        <label for="firstname">Bairro/Distrito</label>
                        <pm-InputText id="bairro" v-model="info.bairro"/>
                    </div>
                    <div class="field col-12 md:col-2" >
                        <label for="firstname">Cidade</label>
                        <pm-InputText id="cidade" v-model="info.cidade"/>
                    </div>
                     <div class="field col-12 md:col-1 estado_responsive" >
                        <label for="firstname">Estado</label>
                        <pm-InputText id="estado" v-model="info.estado"/>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid grid-nogutter justify-content-between">
                    <pm-Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left" />
                    <pm-Button label="Próximo" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </pm-Card>
    </div>
</template>


<script>
import { buscarCEP } from "@/class/buscarCEP";

export default {
    props: {
        dadosPessoa: { type: Array, default: () => [] },
        pg: {},
    },
    data () {
        return {
            info: {
                nome: '',
                nomeMae: '',
                nomePai: '',
                possuiDeficiencia: false,
                deficiencia: '',
                nee:false,
                observacao:'',
                email:'',
                senha:'',
                matricula:null,
                rg:'',
                dataEmissao:'',
                cpf:'',
                dataNascimento:'',
                sexo:'',
                cep:'',
                logradouro:'',
                numero:'',
                complemento:'',
                bairro:'',
                cidade:'',
                estado:'',
                telefone:[],
            },
            cepInfo:'',
            submitted: false,
            validationErrors: {}
        }
    },
    watch: {
        cepInfo() {
            this.info.cep = this.cepInfo;
            let cepTest = this.cepInfo.replace(".", "").replace("-", "").replace("_", "");
            if (cepTest.length == 8) {
                this.BuscarCEPFunction(cepTest)
            }
        }
    },
    methods: {
        async BuscarCEPFunction(cepTest) {
            try {
                let data = await buscarCEP.searchCep(cepTest);
                this.info.estado = (data['uf'] != '' ? data['uf'] : this.info.estado),
                this.info.cidade =  (data['localidade'] != '' ? data['localidade'] : this.info.cidade),
                this.info.bairro =  (data['bairro'] != '' ? data['bairro'] : this.info.bairro),
                this.info.logradouro = (data['logradouro'] != '' ? data['logradouro'] : this.info.logradouro)

            } catch (error) {
                this.answer = 'Error! Could not reach the API. ' + error
            }
        },
        nextPage() {
            this.$emit('nextPage', {info: this.info});
        },
        prevPage() {
            this.$emit('prevPage', {info: this.info});
        },
        validateForm() {
        }
    },
    beforeMount() {
        this.info.nome =   (this.dadosPessoa.nome != undefined ? this.dadosPessoa.nome : this.info.nome);
        this.info.sobrenome =   (this.dadosPessoa.sobrenome != undefined ? this.dadosPessoa.sobrenome : this.info.sobrenome);
        this.info.possuiDeficiencia = this.dadosPessoa.possuiDeficiencia;
        this.info.deficiencia =  this.dadosPessoa.deficiencia;
        this.info.observacao = this.dadosPessoa.observacao;
        this.info.nee = this.dadosPessoa.nee;
        this.info.email =  this.dadosPessoa.email;
        this.info.senha =  this.dadosPessoa.senha;
        this.info.confirmarSenha =  this.dadosPessoa.confirmarSenha;
        this.info.rg =  this.dadosPessoa.rg;
        this.info.rgDataEmissao = (this.dadosPessoa.rgDataEmissao != undefined ? this.dadosPessoa.rgDataEmissao : this.info.rgDataEmissao);
        this.info.cpf =  this.dadosPessoa.cpf;
        this.info.cpfDele = (this.dadosPessoa.cpfDele != undefined ? this.dadosPessoa.cpfDele : '');
        this.info.dataNascimento =  (this.dadosPessoa.dataNascimento != undefined ? this.dadosPessoa.dataNascimento : this.info.dataNascimento);
        this.info.cep =  (this.dadosPessoa.cep != undefined ? this.dadosPessoa.cep : this.info.cep);
        this.info.estado =  this.dadosPessoa.estado;
        this.info.cidade =  this.dadosPessoa.cidade;
        this.info.bairro =  this.dadosPessoa.bairro;
        this.info.logradouro =  this.dadosPessoa.logradouro;
        this.info.numero =  this.dadosPessoa.numero;
        this.info.complemento =  this.dadosPessoa.complemento;
        this.info.telefone =  (this.dadosPessoa.telefone == undefined ? [] : this.dadosPessoa.telefone);
        this.info.sexo =  (this.dadosPessoa.sexo != undefined ? this.dadosPessoa.sexo : this.info.sexo );
        this.info.foto =  this.dadosPessoa.foto;
        this.cepInfo = this.info.cep;
        this.info.matricula =  this.dadosPessoa.matricula;
        this.info.escolaridade =  this.dadosPessoa.escolaridade;
        this.info.id =  (this.dadosPessoa.id != undefined ? this.dadosPessoa.id : this.info.id);
        this.dadosPessoa.jaBuscou = true;
        this.info.funcao = this.dadosPessoa.funcao;
        this.info.login_id = this.dadosPessoa.login_id;
        this.info.emailDele = this.dadosPessoa.emailDele;


    },
}
</script>
